import { Modal } from 'react-bootstrap';
import { RiErrorWarningLine } from "react-icons/ri";
import { useLazyQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

// Custom Component
import { PrimaryButton } from '../../../buttons';
import ErrorToast from '../../../toast/error';

// Utility Service
import { setCheckoutToken } from '../../../../../utils/auth';

// Queries
import { GET_CHECKOUT_TOKEN } from '../../../../../graphql/queries/common/subscription';

// Redux-Functions
import { setRecoveryAlertModal } from '../../../../../redux/userSlice';

const RecoveryAlertModal = (props) => {
    const dispatch = useDispatch();

    const [loadCheckoutToken, { loading }] = useLazyQuery(GET_CHECKOUT_TOKEN, {
        fetchPolicy: 'network-only',
        onError: (error) => {
            ErrorToast(error?.message)
        },
        onCompleted: (data) => {
            setCheckoutToken(data?.getCheckOutToken)
            window.location.href = `${process.env.REACT_APP_SECURE_DOMAIN}/settings?tab=security`
            handleClose()
        }
    });

    /**
       * @function handleSecurityTab
       * @params
       * @description used to redirect to security tab of settings page
   */
    const handleSecurityTab = async () => {
        loadCheckoutToken()
    }

    /**
        * @function handleClose
        * @params
        * @description used to close recovery alert modal
    */
    const handleClose = () => {
        dispatch(setRecoveryAlertModal(false))
    }

    return <Modal className='recovery-alert-modal' contentClassName='border border-danger' show={props.show} onHide={handleClose} backdrop="static" size='md'>
        <Modal.Header className='fs-21 border-0 justify-content-center' closeButton>
            <RiErrorWarningLine className='text-danger me-2' size={30} />
            <span className='w-100 text-capitalize fw-bold'>Security Alert</span>
        </Modal.Header>
        <Modal.Body className='d-flex flex-column align-items-start pt-0 ps-4'>
            <span className='text-dark ms-4'>You have not set your security settings yet. Please complete the account recovery setup to ensure your account's safety. You will be prompted to do this every time you log in until the setup is complete.</span>
            <div className='d-flex justify-content-end align-items-center w-100 mt-3'>
                <span role='button' className={classNames('text-dark me-3', {
                    "opacity-25 pe-none": loading
                })} onClick={handleClose}>Close</span>
                <div>
                    <PrimaryButton disabled={loading} loading={loading} onClick={handleSecurityTab}>Set Up Now</PrimaryButton>
                </div>
            </div>
        </Modal.Body>
    </Modal>
}

export default RecoveryAlertModal;