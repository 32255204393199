import { toast } from 'react-hot-toast';
import { AiFillCloseCircle } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
import classNames from "classnames";

const ErrorToast = (message) => {
    toast.custom((t) => (
        <div
            className={classNames("toast d-flex justify-content-between align-items-start bg-error-toast text-white rounded-1 p-3", {
                "animate__fadeIn": t.visible,
                "animate__fadeOut": !t.visible
            })}
        >
            <div className="d-flex align-items-center">
                <AiFillCloseCircle className='me-2' size={20} />
                <div className='d-flex flex-column align-items-start'>
                    <span className='toast-title fw-bold'>ERROR</span>
                    <span className='toast-message text-start'>{message || 'Something went wrong'}</span>
                </div>
            </div>
            <IoMdClose role='button' size={20} onClick={() => toast.dismiss(t.id)} />
        </div>
    ), {
        position: 'top-center',
        duration: 5000,
        style: {
            background: 'transparent',
            boxShadow: 'none'
        }
    });
}

export default ErrorToast;